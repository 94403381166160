@font-face {
  font-family: "Migra";
  src: url("./fonts/Migra/Migra-Extrabold.otf");
}
@font-face {
  font-family: "Malayalam";
  src: url("./fonts/Malayalam/MalayalamMN.ttc");
}
/* .header{
    background-color: blueviolet;
} */

.logo > a{
  text-decoration: none;
  font-family: "Migra";
  font-size: 48px;
  cursor: pointer;
  color: #6B705C;
}

#links{
  font-family: "Malayalam";
  font-size: 24px;
  color: #565757;

  /* border: 2px solid black;  */
}

.experience > a{
  font-family: "Malayalam";
  font-size: 24px;
  color: #565757;
  text-decoration: none;
  cursor: pointer;
}
.resume > a{
  font-family: "Malayalam";
  font-size: 24px;
  color: #565757;
  text-decoration: none;
  margin-left: 50px;
  cursor: pointer;
}

.projects > a{
  font-family: "Malayalam";
  font-size: 24px;
  color: #565757;
  text-decoration: none;
  margin-left: 50px;
  cursor: pointer;
}
a {
  transition: color 0.2s;
}
a:hover{
  color: #6B705C;
}
/* .jhoText{
  cursor: pointer;
} */
.hamburger-container{
  margin-top: 1em;
  margin-bottom: -5em;
}
.logo-hamburger{
  /* text-decoration: none; */
  font-family: "Migra";
  font-size: 48px;
  cursor: pointer;
  color: #6B705C !important;
}
.hamburger-button{
  border: 1.5px solid #6B705C !important;
}

.links-hamburger{
  font-family: "Malayalam";
  font-size: 20px;
  color: #565757;
}