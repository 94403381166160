@font-face {
  font-family: "Malayalam";
  src: url("./fonts/Malayalam/MalayalamMN.ttc");
}
@font-face {
  font-family: "PT_Sans_bold";
  src: url("./fonts/PT_Sans/PTSans-Bold.ttf");
}
@font-face {
  font-family: "PT_Sans_bold_italic";
  src: url("./fonts/PT_Sans/PTSans-BoldItalic.ttf");
}
@font-face {
  font-family: "PT_Sans_regular";
  src: url("./fonts/PT_Sans/PTSans-Regular.ttf");
}


.emptySpace{
  margin-top: 10em;
}

.sectionName{
  font-family: "Malayalam";
  font-size: 36px;
  text-align: center;
  /* border: 2px solid black; */
}
li {
  font-size: 8px;
}
li span {
  font-size: 18px;
}
.companyName{
/* border: 2px solid black; */
  font-family: "PT_Sans_bold";
  font-size: 24px;
  color: #6B705C;
}
.city{
  text-align: right;
  font-family: "PT_Sans_bold";
  font-size: 24px;
  color: #6B705C;
}
.positionTitle{
/* border: 2px solid black; */
  font-family: "PT_Sans_bold_italic";
  color: #565757;
  font-size: 18px;
}
.jobPosition{
/* border: 2px solid black; */
  margin-top: .5em;
}
.jobDate{
  text-align: right;
  font-family: "PT_Sans_bold_italic";
  color: #565757;
  font-size: 18px;
}

.jodDescription{
  /* border: 2px solid black; */
  font-family: "PT_Sans_regular";
  margin-top: 1em;
}

/* #equinixPic{
  width: 400px;
  height: auto;
} */

#orionPic{
  width:500px;
  height: auto;
  margin-top: -4em;
  /* border: 2px solid red; */
}

#clock{
  width:400px;
  height: auto;
}

#thanks{
  margin-top: 6em;
  font-family: "PT_Sans_regular"; 
  font-size: 24px;
  color: #565757;
}

/* makes the bullet points inside the container  */
ul{
  list-style-position: inside;
}


#drivingRobotPic{
  margin-left: -1.5em;
  width: 450px;
  height: auto;
  /* border: 2px solid black; */
  
  
}