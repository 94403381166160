@font-face {
  font-family: "PT_Sans";
  src: url("./fonts/PT_Sans/PTSans-Regular.ttf");
}
@font-face {
  font-family: "PT_Sans_bold";
  src: url("./fonts/PT_Sans/PTSans-Bold.ttf");
}

.hey{
  font-family: "PT_Sans_bold";
  /* font-weight: 800; */
  font-size: 40px;
  color: #565757;
}

.profilePic{
  height: 400px;
  width: auto;
  /* border: 2px solid black; */
  
}
.imJenny{
  font-family: "PT_Sans_bold";
  /* font-weight: 800; */
  font-size: 40px;
  margin-top: -.3em;
  color: #6B705C;
}

.intro{
  font-family: PT_Sans;
  font-size: 20px;
  padding-top: .5em;
}

#socials{
  padding-top: 2em;
}

.linkedin > a{
  color: #6B705C;
  text-decoration: underline;
}

.email > a{
  color: #6B705C;
  text-decoration: underline;
}
/* .pic{
  border: 2px solid black;
} */
/* img{
  border: 2px solid black;
  margin-left: ;
} */
/* row{
  display: flex;
} */

@media screen and (max-width:420px){  
  .profilePic{
    margin-left: -1em !important;
  }
}